import React from "react";

const WeightEstimatorFormFields = ({
  shape,
  materialFactors,
  handleChange,
  formData,
}) => {
  const materialOptions = Object.entries(materialFactors).map(
    ([material, factor]) => (
      <option key={material} value={factor}>
        {material}
      </option>
    )
  );

  switch (shape) {
    case "square":
      return (
        <div className="grid grid-cols-12 gap-12">
          <div className="col-span-12">
            <label htmlFor="material"> Material type </label>
            <select id="material" name="material" onChange={handleChange}>
              {materialOptions}
            </select>
          </div>
          
          <div className="col-span-12 flex gap-12">
          
            <div>
              <label className="block" htmlFor="thickness">
                Thickness
              </label>
              <input
                id="thickness"
                name="thickness"
                type="number"
                step="0.00001"
                min="0"
                onChange={handleChange}
                value={formData.thickness}
              />
              <small className="block font-normal mt-1">inches</small>
            </div>
            
            <div>
              <label className="block" htmlFor="width">
                Width
              </label>
              <input
                id="width"
                name="width"
                type="number"
                step="0.00001"
                min="0"
                onChange={handleChange}
                value={formData.width}
              />
              <small className="block font-normal mt-1">inches</small>
            </div>
            
            <div>
              <label className="block" htmlFor="length">
                Length
              </label>
              <input
                id="length"
                name="length"
                type="number"
                step="0.00001"
                min="0"
                onChange={handleChange}
                value={formData.length}
              />
              <small className="block font-normal mt-1">inches</small>
            </div>
          
          </div>
          
          <div className="col-span-12">
            <label className="block" htmlFor="pieces">
              Number of pieces
            </label>
            <input
              id="pieces"
              name="pieces"
              type="number"
              step="1"
              min="1"
              onChange={handleChange}
              value={formData.pieces}
            />
          </div>
        </div>
      );

    case "square_tube":
      return (
        <div className="grid grid-cols-12 gap-12">
          <div className="col-span-12">
            <label htmlFor="material"> Material type </label>
            <select id="material" name="material" onChange={handleChange}>
              {materialOptions}
            </select>
          </div>

          <div className="col-span-12 flex gap-12">

          <div>
            <label htmlFor="thickness"> Thickness </label>
            <input
              id="thickness"
              name="thickness"
              type="number"
              step="0.00001"
              min="0"
              onChange={handleChange}
              value={formData.thickness}
            />
            <small className="block font-normal mt-1">inches</small>
          </div>
          <div>
            <label htmlFor="width"> Width  </label>
            <input
              id="width"
              name="width"
              type="number"
              step="0.00001"
              min="0"
              onChange={handleChange}
              value={formData.width}
            />
            <small className="block font-normal mt-1">inches</small>
          </div>
          <div>
            <label htmlFor="length"> Length </label>
            <input
              id="length"
              name="length"
              type="number"
              step="0.00001"
              min="0"
              onChange={handleChange}
              value={formData.length}
            />
            <small className="block font-normal mt-1">inches</small>
          </div>


          <div>
            <label htmlFor="wallThickness"> Wall thickness </label>
            <input
              id="wallThickness"
              name="wallThickness"
              type="number"
              step="0.00001"
              min="0"
              onChange={handleChange}
              value={formData.wallThickness}
            />
            <small className="block font-normal mt-1">inches</small>
          </div>
          </div>
          
          <div className="col-span-12">
            <label htmlFor="pieces"> Number of pieces </label>
            <input
              id="pieces"
              className="w-8"
              name="pieces"
              type="number"
              step="1"
              min="1"
              onChange={handleChange}
              value={formData.pieces}
            />
          </div>
        </div>
      );

    case "sheet":
      return (
        <div className="grid grid-cols-12 gap-12">
          <div className="col-span-12">
            <label htmlFor="material"> Material type </label>
            <select id="material" name="material" onChange={handleChange}>
              {materialOptions}
            </select>
          </div>
          
          <div className="col-span-12 flex gap-12">
          <div>
            <label className="block" htmlFor="thickness">
              Thickness
            </label>
            <input
              id="thickness"
              name="thickness"
              type="number"
              step="0.00001"
              min="0"
              onChange={handleChange}
              value={formData.thickness}
            />
            <small className="block font-normal mt-1">inches</small>
          </div>
          <div>
            <label className="block" htmlFor="width">
              Width 
            </label>
            <input
              id="width"
              name="width"
              type="number"
              step="0.00001"
              min="0"
              onChange={handleChange}
              value={formData.width}
            />
              <small className="block font-normal mt-1">inches</small>
          </div>
          <div>
            <label className="block" htmlFor="length">
              Length 
            </label>
            <input
              id="length"
              name="length"
              type="number"
              step="0.00001"
              min="0"
              onChange={handleChange}
              value={formData.length}
            />
              <small className="block font-normal mt-1">inches</small>
          </div>
          </div>
          
          <div className="col-span-12">
            <label className="block" htmlFor="pieces">
              Number of pieces
            </label>
            <input
              id="pieces"
              name="pieces"
              type="number"
              step="1"
              min="1"
              onChange={handleChange}
              value={formData.pieces}
            />
          </div>
        </div>
      );

    case "round":
      return (
        <div className="grid grid-cols-12 gap-12">
          <div className="col-span-12">
            <label htmlFor="material"> Material type </label>
            <select id="material" name="material" onChange={handleChange}>
              {materialOptions}
            </select>
          </div>

          <div className="col-span-12 flex gap-12">
          
          <div>
            <label htmlFor="diameter"> Diameter </label>
            <input
              id="diameter"
              name="diameter"
              type="number"
              step="0.00001"
              min="0"
              onChange={handleChange}
              value={formData.diameter}
            />
            <small className="block font-normal mt-1">inches</small>
          </div>
          <div>
            <label htmlFor="length"> Length </label>
            <input
              id="length"
              name="length"
              type="number"
              step="0.00001"
              min="0"
              onChange={handleChange}
              value={formData.length}
            />
              <small className="block font-normal mt-1">inches</small>
          </div>
          </div>
          
          <div className="col-span-12">
            <label htmlFor="pieces"> Number of pieces </label>
            <input
              id="pieces"
              name="pieces"
              type="number"
              step="1"
              min="1"
              onChange={handleChange}
              value={formData.pieces}
            />
          </div>
        </div>
      );

    case "round_tube":
      return (
        <div className="grid grid-cols-12 gap-12">
          <div className="col-span-12">
            <label htmlFor="material"> Material Type </label>
            <select id="material" name="material" onChange={handleChange}>
              {materialOptions}
            </select>
          </div>
          
          <div className="col-span-12 flex gap-12">

          <div>
            <label htmlFor="diameter"> Outside diameter </label>
            <input
              id="diameter"
              name="diameter"
              type="number"
              step="0.00001"
              min="0"
              onChange={handleChange}
              value={formData.diameter}
            />
              <small className="block font-normal mt-1">inches</small>
          </div>
          <div>
            <label htmlFor="wallThickness"> Wall thickness </label>
            <input
              id="wallThickness"
              name="wallThickness"
              type="number"
              step="0.00001"
              min="0"
              onChange={handleChange}
              value={formData.wallThickness}
            />
              <small className="block font-normal mt-1">inches</small>
          </div>
          <div>
            <label htmlFor="length"> Length </label>
            <input
              id="length"
              name="length"
              type="number"
              step="0.00001"
              min="0"
              onChange={handleChange}
              value={formData.length}
            />
              <small className="block font-normal mt-1">inches</small>
          </div>
          </div>
          
          <div className="col-span-12">
            <label htmlFor="pieces"> Number of pieces </label>
            <input
              id="pieces"
              name="pieces"
              type="number"
              step="1"
              min="1"
              onChange={handleChange}
              value={formData.pieces}
            />
          </div>
        </div>
      );

    case "hex":
      return (
        <div className="grid grid-cols-12 gap-12">
          <div className="col-span-12">
            <label htmlFor="material"> Material Type </label>
            <select id="material" name="material" onChange={handleChange}>
              {materialOptions}
            </select>
          </div>
          
          <div className="col-span-12 flex gap-12">

          <div>
            <label htmlFor="widthAcross"> Width across flats </label>
            <input
              id="widthAcross"
              name="width"
              type="number"
              step="0.00001"
              min="0"
              onChange={handleChange}
              value={formData.width}
            />
              <small className="block font-normal mt-1">inches</small>
          </div>
          <div>
            <label htmlFor="length"> Length </label>
            <input
              id="length"
              name="length"
              type="number"
              step="0.00001"
              min="0"
              onChange={handleChange}
              value={formData.length}
            />
              <small className="block font-normal mt-1">inches</small>
          </div>
          </div>
          
          <div className="col-span-12">
            <label htmlFor="pieces"> Number of pieces </label>
            <input
              id="pieces"
              name="pieces"
              type="number"
              step="1"
              min="1"
              onChange={handleChange}
              value={formData.pieces}
            />
          </div>
        </div>
      );

    case "angle":
      return (
        <div className="grid grid-cols-12 gap-12">
          <div className="col-span-12">
            <label htmlFor="material"> Material Type </label>
            <select id="material" name="material" onChange={handleChange}>
              {materialOptions}
            </select>
          </div>

          <div className="col-span-12 flex gap-12">
          
          <div>
            <label htmlFor="leg1"> Leg 1 </label>
            <input
              id="leg1"
              name="leg1"
              type="number"
              step="0.00001"
              min="0"
              onChange={handleChange}
              value={formData.leg1}
            />
              <small className="block font-normal mt-1">inches</small>
          </div>
          <div>
            <label htmlFor="leg2"> Leg 2 </label>
            <input
              id="leg2"
              name="leg2"
              type="number"
              step="0.00001"
              min="0"
              onChange={handleChange}
              value={formData.leg2}
            />
              <small className="block font-normal mt-1">inches</small>
          </div>
          <div>
            <label htmlFor="thickness"> Thickness </label>
            <input
              id="thickness"
              name="thickness"
              type="number"
              step="0.00001"
              min="0"
              onChange={handleChange}
              value={formData.thickness}
            />
              <small className="block font-normal mt-1">inches</small>
          </div>
          <div>
            <label htmlFor="length"> Length </label>
            <input
              id="length"
              name="length"
              type="number"
              step="0.00001"
              min="0"
              onChange={handleChange}
              value={formData.length}
            />
              <small className="block font-normal mt-1">inches</small>
          </div>
          </div>
          
          <div className="col-span-12">
            <label htmlFor="pieces"> Number of pieces </label>
            <input
              id="pieces"
              name="pieces"
              type="number"
              step="1"
              min="1"
              onChange={handleChange}
              value={formData.pieces}
            />
          </div>
        </div>
      );

    default:
      return <div> Please select a shape </div>;
  }
};

export default WeightEstimatorFormFields;
